<template>
<div>
    <b-overlay
      show
      spinner-variant="primary"
      spinner-type="grow"
      spinner-large
      rounded="sm"
      style="top: 25em;"
      v-if="overlayStatus"
    >
    </b-overlay>
    
    <div v-if="dataBalances.length === 0 && overlayStatus === false">
      <Empty
        :no-button="true"
        icon="LoaderIcon"
        title="No hay datos para mostrar"
        content="Actualmente no hay ajustes pendientes de ordenes de compra, cuando haya algun ajuste pendiente, aparecerá en esta pantalla."
      />
    </div>

    <b-alert
      variant="primary"
      :show="dataBalances.length < 0"
    >
      <h4 class="alert-heading">
        No hay cuentas por cobrar.
      </h4>
    </b-alert>

    <b-row class="match-height">
      <b-col
        lg="4"
        md="4"
        v-for="(balance, indexBalance) in dataBalances"
        :key="indexBalance"
      >
        <b-card
          no-body
          class="card-apply-job"
        >
          <b-card-header class="pb-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
              </b-media-aside>
              <b-media-body>
                <h4 class="mb-0">
                  {{ balance.companyName }}
                </h4>
              </b-media-body>
            </b-media>
            <!-- <b-badge
              pill
              variant="light-primary"
            >
              Urgente
            </b-badge> -->
          </b-card-header>
          <b-card-body>
            <div class="total">
              <div>
                <sup class="text-body">
                </sup>
                <h1 class="d-inline mr-25">
                  $ {{  formatterNumber(balance.balance) }}
                </h1>
              </div>
              <div>
                <h1 class="d-inline mr-25">
                  Total
                </h1>
              </div>
            </div>

            <!--/ button actions  -->
            <template v-if="balance.balance > 0">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="primary"
                @click="$router.push({ name: 'add-purchaseOrdersAdjustment', params: { id:balance.idCompany, balance: balance.balance }})"
              >
                Crear ajuste
              </b-button>
            </template>

            <!--/ button actions  -->
            <template v-if="balance.balance < 0">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="primary"
                @click="$router.push({ name: 'paymentBills', params: { id:balance.idCompany, paymentType:'adjustmentBalance' }})"
              >
                Hacer pago
              </b-button>
            </template>
          </b-card-body>
        </b-card>
      </b-col>
  </b-row>
</div>


</template>


<style lang="scss" scoped>
  @import './adjustmentBalance.css';
</style>

<style lang="scss" scoped>
  .scroll-area {
    height: 10.5em;
  }
</style> 

<script>
import {
  BCard, BCardText, BCardBody, BCardTitle, BCardSubTitle, 
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, 
  BButton, BAlert, BTable, BOverlay, VBTooltip, BPagination, BTooltip,
  BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge
} from 'bootstrap-vue'
import Empty from '../../components/empty-page/Empty'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import data from '@/router/routes/ui-elements'
import utils from '../../../utils/utils.js';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'


import store from '@/store'



export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    vSelect,
    BTable,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BPagination,
    BTooltip,

    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Empty,

    // 3rd party
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      overlayStatus: true,
      dataBalances: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
        wheelPropagation: true,
      },
    }
  },
  created(){
    this.getBalances();
  },
  methods: {
    getBalances() {
      this.$axios.get(this.$services + 'orders/get_adjustment_balances', {
        params: {
          type: 'PROVEEDOR'
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.overlayStatus = false;
          this.dataBalances = res.data.data;
        } else {

        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
      });
    },
    formatterNumber(value){
      return utils.numberWithCommas(value)
    },
    remainingDays(timeStamp) {
      // Diferencia de días
      let expression;
      let days = this.diffDays(timeStamp);
      
      (Math.abs(days) == 1) ? expression = ' día' : expression = ' días';

      if (days < 0) { // Delay
        return 'Retrasado por ' + Math.abs(days) + expression;
      } else if (days == 0) { // Important
        return 'Cobrar hoy';
      } else if (days == 1) { // Important
        return 'Cobrar mañana';
      } else if (days >= 2 && days <= 3) { // Warning
        return 'Cobrar en ' + days + expression;
      } else { // Not warnign
        return 'Cobrar en ' + days + expression;
      }
    },
    priority(timeStamp) {

      let days = this.diffDays(timeStamp);

      if (days <= 1) { // Delay
        return 'danger';
      } else if (days >= 2 && days <= 3) { // Warning
        return 'warning';
      } else { // Not warnign
        return 'primary';
      }
    },
    diffDays(timeStamp) {

      // Set 00:00 both dates
      let today = this.$moment().set({hour:0,minute:0,second:0,millisecond:0});
      let deadline = this.$moment(timeStamp).set({hour:0,minute:0,second:0,millisecond:0});

      let diffDays = deadline.diff(today, 'days');

      return diffDays;
    }
  },
}
</script>
